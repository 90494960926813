// // Ionic Variables and Theming. For more info, please see:
// // http://ionicframework.com/docs/theming/

// /** Ionic CSS Variables **/
:root {

  --ion-color-primary: #69B42E;
  --ion-color-primary-rgb: 105, 180, 46;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #5c9e28;
  --ion-color-primary-tint: #78bc43;



  //   /** button -red**/
  //   --ion-color-button: #e06666;

  /** secondary -grey**/
  // --ion-color-secondary: grey;
  // --ion-color-secondary-rgb: 0, 0, 0;
  // --ion-color-secondary-contrast: #ffffff;
  // --ion-color-secondary-contrast-rgb: 255, 255, 255;
  // --ion-color-secondary-shade: #000000;
  // --ion-color-secondary-tint: #1a1a1a;

  //   /** tertiary- light grey **/
  //   --ion-color-tertiary: #eeeeee;
  //   --ion-color-tertiary-rgb: 112, 68, 255;
  //   --ion-color-tertiary-contrast: #ffffff;
  //   --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  //   --ion-color-tertiary-shade: #633ce0;
  //   --ion-color-tertiary-tint: #7e57ff;

    /** success **/
    --ion-color-success: #69B42E;
    --ion-color-success-rgb: 105, 180, 46;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade:  #5c9e28;
    --ion-color-success-tint: #78bc43;

  //   /** warning **/
  //   --ion-color-warning: #ffce00;
  //   --ion-color-warning-rgb: 255, 206, 0;
  //   --ion-color-warning-contrast: #ffffff;
  //   --ion-color-warning-contrast-rgb: 255, 255, 255;
  //   --ion-color-warning-shade: #e0b500;
  //   --ion-color-warning-tint: #ffd31a;

  /** danger **/
  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245, 61, 61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  //   /** dark **/
  //   --ion-color-dark: rgba(0, 0, 0, 0.54);
  //   --ion-color-dark-rgb: 34, 34, 34;
  //   --ion-color-dark-contrast: #ffffff;
  //   --ion-color-dark-contrast-rgb: 255, 255, 255;
  //   --ion-color-dark-shade: #1e2023;
  //   --ion-color-dark-tint: #383a3e;

  //   /** medium **/
  //   --ion-color-medium: #989aa2;
  //   --ion-color-medium-rgb: 152, 154, 162;
  //   --ion-color-medium-contrast: #ffffff;
  //   --ion-color-medium-contrast-rgb: 255, 255, 255;
  //   --ion-color-medium-shade: #86888f;
  //   --ion-color-medium-tint: #a2a4ab;

  //   /** light **/
  //   --ion-color-light: #ffffff;
  //   --ion-color-light-rgb: 244, 244, 244;
  //   --ion-color-light-contrast: #000000;
  //   --ion-color-light-contrast-rgb: 0, 0, 0;
  //   --ion-color-light-shade: #d7d8da;
  //   --ion-color-light-tint: #f5f6f9;

  //    /** Folder entries status color **/
  //    --ion-color-status-approved: #a0c87f;
  //    --ion-color-status-rejected:#f04141;
  //    --ion-color-status-pending: #eabb7f;
  //    --ion-color-red: #f04141;
  //    --ion-color-black: black;
  //    --ion-color-status-waitingforapproval:  #3da1a6;

  //   //  Object status colors
  //   --ion-color-object-status-red: #ff0505;
  //   --ion-color-object-status-orange: #ff5104;
  //   --ion-color-object-status-green: #22b604;

  //   // --ion-font-family: Source Sans Pro !important;
  //   --ion-font-family: DIN Pro Condensed !important;

  --ion-item-background: transparent;
  --ion-background-color: #f6fbf0;
  --ion-card-background: #fff;
  --ion-header-background: #fff;
  //   --ion-card-border-radius: 8px;
  //   --ion-card-padding: 16px;
  //   --ion-card-shadow-color: rgba(0, 0, 0, 0.08);
  //   --ion-card-shadow-spread: 1px;
  //   --ion-card-shadow-x: 0;
  //   --ion-card-shadow-y: 2px;



  //datatable color
	--ion-color-table-footer-header: #f6fbf0;
	--ion-color-table-footer-header-rgb: 246,251,240;
	--ion-color-table-footer-header-contrast: #181818;
	--ion-color-table-footer-header-contrast-rgb: 0,0,0;
	--ion-color-table-footer-header-shade: #d8ddd3;
	--ion-color-table-footer-header-tint: #f7fbf2;

}