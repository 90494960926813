/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// ngx-datatable CSS
@import "node_modules/@siemens/ngx-datatable/index.css";
@import "node_modules/@siemens/ngx-datatable/themes/material.css";
@import "node_modules/@siemens/ngx-datatable/assets/icons.css";

//datepicker css
@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
// ngx-smart-modal
@import "~ngx-smart-modal/ngx-smart-modal.css";

//Ionic 6 patch ..not showing ion-alert
.overlay-hidden.sc-ion-alert-md-h {
  display: flex !important;
}

// Material icons
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/Material-Icons.woff2) format("woff2");
}

/* source-sans-pro - latin */
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: normal;
  src: url("./assets/fonts/SourceSansPro-Regular.ttf") format("truetype");
}

// @font-face {
//     font-family: 'DIN Pro Condensed';
//     font-style: normal;
//     font-weight: 400;
//     src: url('./assets/fonts/DIN-Pro-Condensed-Regular.ttf') format('truetype');

//   }

// @font-face {
//     font-family: 'DIN Pro Condensed';
//     font-style: normal;
//     font-weight: 900;
//     src: url('./assets/fonts/DIN-Pro-Condensed-Black.otf') format('truetype');

//   }

// @font-face {
//     font-family: 'DIN Pro';
//     font-style: normal;
//     font-weight: 300;
//     src: url('./assets/fonts/DIN-Pro-Light.otf') format('truetype');

//   }

//   @font-face {
//     font-family: 'DIN Pro';
//     src: url('./assets/fonts/DIN-Pro-Light-Italic.ttf') format('truetype');
//     font-weight: 300;
//     font-style: italic;
// }

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  // -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

// Global CSS

.ion-page {
  top: 48px;
}

.app-page {
  top: 0px;
}

//required if header is not needed in login page
// app-login {
//   top: 0 !important;
// }

//ngx-datatable

.main-content-part .ngx-datatable.material,
.main-content-part ngx-datatable {
  margin-top: 56px !important;
}

.ngx-datatable.material.single-selection .datatable-body-row.active,
.ngx-datatable.material.single-selection
  .datatable-body-row.active
  .datatable-row-group,
.ngx-datatable.material.multi-selection .datatable-body-row.active,
.ngx-datatable.material.multi-selection
  .datatable-body-row.active
  .datatable-row-group,
.ngx-datatable.material.multi-click-selection .datatable-body-row.active,
.ngx-datatable.material.multi-click-selection
  .datatable-body-row.active
  .datatable-row-group {
  background-color: #eeeeee !important;
}

.ngx-datatable.material.single-selection .datatable-body-row.active:hover,
.ngx-datatable.material.single-selection
  .datatable-body-row.active:hover
  .datatable-row-group,
.ngx-datatable.material.multi-selection .datatable-body-row.active:hover,
.ngx-datatable.material.multi-selection
  .datatable-body-row.active:hover
  .datatable-row-group,
.ngx-datatable.material.multi-click-selection .datatable-body-row.active:hover,
.ngx-datatable.material.multi-click-selection
  .datatable-body-row.active:hover
  .datatable-row-group {
  background-color: #eeeeee !important;
}

.ngx-datatable
  .datatable-header
  .datatable-header-cell
  .datatable-header-cell-template-wrap {
  overflow: initial;
}

.ngx-datatable.material.messageScreen datatable-body-row:hover,
.ngx-datatable.material.tableList datatable-body-row:hover {
  cursor: default !important;
}

.ngx-datatable.material .datatable-footer .page-count {
  display: none !important;
}

.ngx-datatable.material .datatable-header .datatable-header-cell {
  //datatable header color
  background-color: var(--ion-color-table-footer-header);
  color: var(--ion-color-table-footer-header-contrast);
  font-size: 14px;
}

ngx-datatable,
.ngx-datatable.material {
  box-shadow: none;
  --background: var(--ion-color-light);
}

.ngx-datatable.material.messageScreen .datatable-body {
  background: #f8f8f8 !important;
}

.ngx-datatable.material .empty-row {
  margin-top: 15px !important;
  text-align: center;
  color: var(--ion-color-dark);
}

.dashboard
  .ngx-datatable.fixed-header
  .datatable-header
  .datatable-header-inner
  .datatable-header-cell {
  padding-left: 2px !important;
}

.dashboard
  .ngx-datatable.material
  .datatable-body
  .datatable-body-row
  .datatable-body-cell {
  padding-left: 2px !important;
  padding-left: 2px !important;
  padding-top: 9px;
  padding-bottom: 9px;
  padding-right: 2px;
}

.ngx-datatable
  .datatable-header
  .datatable-header-cell
  .datatable-header-cell-template-wrap {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.datatable-body-cell.checkbox-cell .datatable-body-cell-label {
  overflow: initial;
}

.ngx-datatable.material.messageScreen
  .datatable-body
  .datatable-body-row
  .datatable-body-cell {
  padding: 0 !important;
}

.ngx-datatable.material.messageScreen
  .datatable-row-wrapper:not(:last-child)
  .datatable-body-cell {
  padding-bottom: 0 !important;
}

.ngx-datatable.material .datatable-header {
  border-bottom: none;
}

.ngx-datatable.material.border-header .datatable-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.ngx-datatable.material .datatable-footer {
  border-top: none;
  //datatable footer color / padding
  background-color: var(--ion-color-table-footer-header);
  color: var(--ion-color-table-footer-header-contrast) !important;
  padding-right: 25px;
  a {
    color: var(--ion-color-table-footer-header-contrast) !important;
  }
}

.ngx-datatable.material.border-footer .datatable-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.ngx-datatable .datatable-footer .datatable-pager {
  flex: 1 1 60% !important;
  //footer responsiveness
  white-space: nowrap;
}

.ngx-datatable.material .datatable-body .datatable-row-detail {
  padding: 0;
}

.domainSelect .datatable-body-row:hover,
.ngx-datatable.material:not(.cell-selection)
  .datatable-body-row:hover
  .datatable-row-group {
  background-color: transparent !important;
}

.ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.ngx-datatable.material:not(.cell-selection)
  .datatable-body-row:hover
  .datatable-row-group {
  background-color: #f8f8f8 !important;
  transition-property: background;
  transition-duration: 0.3s;
  transition-timing-function: linear;
}

//data-table

datatable-body-row:hover {
  cursor: pointer;
}

.data-table {
  border: 1px solid var(--ion-color-light-shade);
  font-size: 14px;
  // margin: 38px 100px;
  // margin-top: 0;
  //min-height: 200px;
}

.data-table ion-col {
  padding: 0 5px;
}

.data-table .datatable-body {
  height: calc(100vh - 205px) !important;
  overflow-x: hidden;
  overflow-y: auto;
}

.datatable-checkbox input[type="checkbox"]:checked:before {
  // border-color: var(--ion-color-primary) !important;
  border-color: var(--ion-color-primary) !important;
}

datatable-body-cell.inlineListing {
  padding: 0 !important;
}

.report-overview .users .data-table .datatable-body {
  height: calc(100vh - 348px) !important;
}

.report-objectfile .objectfile .data-table .datatable-body {
  height: calc(100vh - 390px) !important;
}

.report-overview .objects .data-table .datatable-body {
  height: calc(100vh - 272px) !important;
}

.report-overview .companies .data-table .datatable-body {
  height: calc(100vh - 272px) !important;
}

.report-overview .objectGroups .data-table .datatable-body {
  height: calc(100vh - 272px) !important;
}

.paging-info {
  margin-right: 20px;
  font-size: 12px;
  font-weight: 400;
  //footer responsiveness
  white-space: nowrap;
}

// .main-content {
// --padding-start: 30px !important;
// }

// .detail-card {
//   margin: 38px 100px;
//   margin-top: 0;
// }

.input-group {
  width: 100%;
  display: flex;
  background: #ffffff;
  align-items: center;
  // border-radius: 3px;
  // border-bottom: 3px solid #dfdfdf;
  // box-shadow: inset 0px -3px 0 0 #dfdfdf;
}

.input-group .input-icon[slot="start"] {
  padding-right: 0 !important;
}

.input-group .input-icon[slot="end"] {
  padding-left: 0 !important;
}
// .checkbox-item ion-checkbox[slot="start"] {
//   padding-right: 0 !important;
// }
// .checkbox-item ion-checkbox[slot="end"] {
//   padding-left: 0 !important;
// }

.input-button {
  position: relative;
  top: 9px;
  ion-icon {
    font-size: 26px;
  }
}

.invalid-feedback {
  color: var(--ion-color-danger);
  font-size: 12px;
  // margin-top: 14px;
  padding-top: 12px;
  // text-align: center;
  width: 100%;
  padding-left: 18px;
}

.checkbox-item,
.radio-item {
  --ripple-color: transparent !important;
  --background-hover-opacity: 0 !important;
}

.checkbox-item ion-label,
.radio-item ion-label {
  font-size: 16px !important;
  font-weight: 500;
}

.checkbox-item,
.radio-item {
  --ripple-color: transparent !important;
  --background-hover-opacity: 0 !important;
}

// hover background color
// .sc-ion-buttons-md-s .button-clear{
//   --background-hover: #000000f5 !important;
// }
.sc-ion-buttons-md-s .button {
  --background-hover: #000000 !important;
}

.checkbox-item,
.radio-item {
  padding-left: 14px;
  background-color: transparent !important;
}

.input-icon {
  //checkmark-sharp color
  color: var(--ion-color-primary);
  // border-radius: 50%;
}

.input-icon[name="checkmark-sharp"] {
  font-size: 19px;
  padding: 10px;
}

ion-button.menu-button {
  min-width: auto !important;
}

.fa-icon-button {
  height: 48px !important;
  width: 48px !important;
  border-radius: 50% !important;
  overflow: auto !important;
}

.img-flag {
  height: 20px;
  width: 20px;
  margin-top: -3px;
  margin-right: 5px;
}

.user-tabs ion-segment-button {
  width: 100%;
}

.header {
  // font-size: 38px;
  // font-weight: 900;
  margin: 0;
  // margin-left:6px;
  background-color: var(--ion-header-background);
  font-weight: 500;
  font-size: 20px;
  color: var(--ion-color-primary-text);
}

.sub-header {
  font-size: 16px;
  margin: 0;
  margin-top: 10px;
}

.ngx-modal-selectLocale,
.ngx-modal-selectUrgency,
.ngx-modal-selectRent {
  opacity: 0;
}

.ngx-modal-activityListFoldersFilter
  .ui-tree
  .ui-tree-container
  .ui-treenode
  .ui-treenode-content
  .ui-treenode-label,
.ngx-modal-objectTypeFoldersTree
  .ui-tree
  .ui-tree-container
  .ui-treenode
  .ui-treenode-content
  .ui-treenode-label,
.ngx-modal-folderEntryFoldersTree
  .ui-tree
  .ui-tree-container
  .ui-treenode
  .ui-treenode-content
  .ui-treenode-label,
.ngx-modal-addNewFoldersTree
  .ui-tree
  .ui-tree-container
  .ui-treenode
  .ui-treenode-content
  .ui-treenode-label,
.ngx-modal-objectTemplateFoldersTree
  .ui-tree
  .ui-tree-container
  .ui-treenode
  .ui-treenode-content
  .ui-treenode-label,
.ngx-modal-approveUploadsFoldersFilter
  .ui-tree
  .ui-tree-container
  .ui-treenode
  .ui-treenode-content
  .ui-treenode-label,
.ngx-modal-userActivityFoldersFilter
  .ui-tree
  .ui-tree-container
  .ui-treenode
  .ui-treenode-content
  .ui-treenode-label,
.ngx-modal-FoldersFilter
  .ui-tree
  .ui-tree-container
  .ui-treenode
  .ui-treenode-content
  .ui-treenode-label,
.ngx-modal-objectTemplateFoldersTree
  .ui-tree
  .ui-tree-container
  .ui-treenode
  .ui-treenode-content
  .ui-treenode-label {
  width: 77% !important;
  cursor: pointer;
}

.ngx-modal-objectsFilter.nsm-dialog,
.ngx-support-model.nsm-dialog,
.ngx-copyFolder-model.nsm-dialog,
.ngx-copyObjectType-model.nsm-dialog,
.ngx-privacy-model.nsm-dialog,
.ngx-modal-confirmPassword.nsm-dialog,
.ngx-modal-confirmProfilePassword.nsm-dialog,
.ngx-modal-confirmOTPCode.nsm-dialog,
.ngx-modal-confirmResetCountPassword.nsm-dialog,
.ngx-modal-activityListFilter.nsm-dialog,
.ngx-copyObjectTypeFolder-model.nsm-dialog,
.ngx-modal-approveUploadsFilter.nsm-dialog,
.ngx-modal-usersFilter.nsm-dialog,
.ngx-modal-activityListFoldersFilter.nsm-dialog,
.ngx-modal-objectTypeFoldersTree.nsm-dialog,
.ngx-modal-folderEntryFoldersTree.nsm-dialog,
.ngx-modal-addNewFoldersTree.nsm-dialog,
.ngx-modal-objectTemplateFoldersTree.nsm-dialog,
.ngx-modal-approveUploadsFoldersFilter.nsm-dialog,
.ngx-modal-userActivityFoldersFilter.nsm-dialog,
.ngx-modal-templateFolders.nsm-dialog,
.ngx-modal-FoldersFilter.nsm-dialog,
.ngx-modal-userInviteModal.nsm-dialog {
  top: 50%;
  transform: translateY(-50%);
}

.ngx-modal-activityListFoldersFilter .nsm-content,
.ngx-modal-objectTypeFoldersTree .nsm-content,
.ngx-modal-folderEntryFoldersTree .nsm-content,
.ngx-modal-addNewFoldersTree .nsm-content,
.ngx-modal-objectTemplateFoldersTree .nsm-content,
.ngx-modal-userActivityFoldersFilter .nsm-content,
.ngx-modal-approveUploadsFoldersFilter .nsm-content {
  max-height: 546px !important;
  overflow: hidden;
  padding: 0 !important;
}

.ngx-modal-objectsFilter.nsm-dialog,
.ngx-support-model.nsm-dialog,
.ngx-copyFolder-model.nsm-dialog,
.ngx-copyObjectType-model.nsm-dialog,
.ngx-privacy-model.nsm-dialog,
.ngx-modal-confirmPassword.nsm-dialog,
.ngx-modal-confirmProfilePassword.nsm-dialog,
.ngx-modal-confirmOTPCode.nsm-dialog,
.ngx-modal-confirmResetCountPassword.nsm-dialog,
.ngx-modal-activityListFilter.nsm-dialog,
.ngx-copyObjectTypeFolder-model.nsm-dialog,
.ngx-modal-approveUploadsFilter.nsm-dialog,
.ngx-modal-usersFilter.nsm-dialog,
.ngx-modal-activityListFoldersFilter.nsm-dialog,
.ngx-modal-objectTypeFoldersTree.nsm-dialog,
.ngx-modal-folderEntryFoldersTree.nsm-dialog,
.ngx-modal-addNewFoldersTree.nsm-dialog,
.ngx-modal-objectTemplateFoldersTree.nsm-dialog,
.ngx-modal-approveUploadsFoldersFilter.nsm-dialog,
.ngx-modal-userActivityFoldersFilter.nsm-dialog,
.ngx-modal-templateFolders.nsm-dialog,
.ngx-modal-FoldersFilter.nsm-dialog,
.ngx-modal-userInviteModal.nsm-dialog {
  top: 50%;
  transform: translateY(-50%);
}

.ngx-modal-activityListFoldersFilter .nsm-content,
.ngx-modal-objectTypeFoldersTree .nsm-content,
.ngx-modal-folderEntryFoldersTree .nsm-content,
.ngx-modal-addNewFoldersTree .nsm-content,
.ngx-modal-objectTemplateFoldersTree .nsm-content,
.ngx-modal-userActivityFoldersFilter .nsm-content,
.ngx-modal-approveUploadsFoldersFilter .nsm-content {
  max-height: 546px !important;
  overflow: hidden;
  padding: 0 !important;
}

.ngx-modal-activityListFoldersFilter
  .nsm-content
  ion-grid
  ion-row.ion-row-scroll,
.ngx-modal-objectTypeFoldersTree .nsm-content ion-grid ion-row.ion-row-scroll,
.ngx-modal-folderEntryFoldersTree .nsm-content ion-grid ion-row.ion-row-scroll,
.ngx-modal-addNewFoldersTree .nsm-content ion-grid ion-row.ion-row-scroll,
.ngx-modal-objectTemplateFoldersTree
  .nsm-content
  ion-grid
  ion-row.ion-row-scroll,
.ngx-modal-userActivityFoldersFilter
  .nsm-content
  ion-grid
  ion-row.ion-row-scroll,
.ngx-modal-approveUploadsFoldersFilter
  .nsm-content
  ion-grid
  ion-row.ion-row-scroll {
  overflow-y: scroll;
  margin: 0.5rem 1rem 0.5rem 1rem;
  max-height: 416px !important;
}

.ngx-modal-addNewFoldersTree .nsm-content ion-grid ion-row.ion-row-scroll {
  max-height: 368px !important;
}

.ngx-modal-activityListFoldersFilter
  .nsm-content
  ion-grid
  ion-row.ion-row-fixed,
.ngx-modal-objectTypeFoldersTree .nsm-content ion-grid ion-row.ion-row-fixed,
.ngx-modal-folderEntryFoldersTree .nsm-content ion-grid ion-row.ion-row-fixed,
.ngx-modal-addNewFoldersTree .nsm-content ion-grid ion-row.ion-row-fixed,
.ngx-modal-objectTemplateFoldersTree
  .nsm-content
  ion-grid
  ion-row.ion-row-fixed,
.ngx-modal-userActivityFoldersFilter
  .nsm-content
  ion-grid
  ion-row.ion-row-fixed,
.ngx-modal-approveUploadsFoldersFilter
  .nsm-content
  ion-grid
  ion-row.ion-row-fixed {
  padding: 0rem 1rem 1rem 0;
}

.ngx-modal-userActivityFoldersFilter p-tree,
.ngx-modal-activityListFoldersFilter p-tree,
.ngx-modal-objectTypeFoldersTree p-tree,
.ngx-modal-folderEntryFoldersTree p-tree,
.ngx-modal-addNewFoldersTree p-tree,
.ngx-modal-objectTemplateFoldersTree p-tree,
.ngx-modal-approveUploadsFoldersFilter p-tree {
  max-width: 100%;
  width: 100%;
}

.ngx-modal-activityListFoldersFilter p-tree .ui-chkbox,
.ngx-modal-objectTypeFoldersTree p-tree .ui-chkbox,
.ngx-modal-folderEntryFoldersTree p-tree .ui-chkbox,
.ngx-modal-addNewFoldersTree p-tree .ui-chkbox,
.ngx-modal-objectTemplateFoldersTree p-tree .ui-chkbox,
.ngx-modal-userActivityFoldersFilter p-tree .ui-chkbox,
.ngx-modal-approveUploadsFoldersFilter p-tree .ui-chkbox {
  position: relative;
  top: 1px;
}

.ngx-modal-activityListFoldersFilter p-tree .ui-treenode-label,
.ngx-modal-userActivityFoldersFilter p-tree .ui-treenode-label,
.ngx-modal-approveUploadsFoldersFilter p-tree .ui-treenode-label,
.ngx-modal-objectTypeFoldersTree p-tree .ui-treenode-label,
.ngx-modal-folderEntryFoldersTree p-tree .ui-treenode-label,
.ngx-modal-addNewFoldersTree p-tree .ui-treenode-label,
.ngx-modal-objectTemplateFoldersTree p-tree .ui-treenode-label,
.ngx-modal-objectTypeFoldersTree p-tree ion-text,
.ngx-modal-folderEntryFoldersTree p-tree ion-text,
.ngx-modal-addNewFoldersTree p-tree ion-text,
.ngx-modal-activityListFoldersFilter p-tree ion-text,
.ngx-modal-objectTemplateFoldersTree p-tree ion-text,
.ngx-modal-userActivityFoldersFilter p-tree ion-text,
.ngx-modal-approveUploadsFoldersFilter p-tree ion-text,
.ngx-modal-FoldersFilter p-tree ion-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ngx-modal-FoldersFilter p-tree .ui-treenode-label,
.ngx-modal-FoldersFilter p-tree ion-text {
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}

.ngx-modal-FoldersFilter p-tree {
  max-width: 100%;
  width: 100%;
}

.ngx-modal-FoldersFilter .nsm-content {
  max-height: 546px !important;
  overflow: hidden;
  padding: 0 !important;
}

.ngx-modal-userInviteModal .nsm-content {
  max-height: 546px !important;
  overflow: hidden;
  padding: 0 !important;

  //--background: #f2f2f2;
  //background: #f2f2f2;
}

.ngx-modal-userInviteModal .nsm-content ion-grid ion-row.ion-row-scroll {
  overflow-y: scroll;
  margin: 0.5rem 1rem 0.5rem 1rem;
  max-height: 416px !important;
}

.ngx-modal-FoldersFilter .nsm-content ion-grid ion-row.ion-row-scroll {
  overflow-y: scroll;
  margin: 0.5rem 1rem 0.5rem 1rem;
  max-height: 416px !important;
}

.ngx-modal-ModalBgColor .nsm-content {
  padding-left: 0 !important;
  padding-right: 0 !important;
  --background: #eeeeee;
  background: #eeeeee;
}

ion-item {
  --inner-border-width: 0;
  // --padding-start: 0;
  --padding-end: 0;
  --inner-padding-end: 0;
}

ion-select {
  font-size: 14px;
  width: 100%;
  min-width: 100%;
  padding-left: 18px !important;
  padding-right: 12px !important;
}

ion-item.sc-ion-select-popover-md {
  height: 45px;
}

ion-label.sc-ion-select-popover-md {
  font-size: 15px !important;
}

ion-input,
textarea {
  --padding-start: 18px !important;
  --padding-end: 12px !important;
  font-size: 14px !important;
  color: #282828 !important;
}

textarea {
  // min-height: 100px !important;
  height: 79px;
  border: none;
  // padding: 8px !important;
  resize: vertical;
  // overflow: hidden;
}

textarea:focus {
  border: none;
  outline: none;
}

.textarea-lg textarea {
  min-height: 250px !important;
}

.main-content {
  ion-card {
    .input-group,
    ion-item,
    .dateTimeControl {
      background-color: #f5f5f5;
    }

    .input-group,
    ion-item,
    .dateTimeControl {
      border-radius: 6px;
    }

    ion-list {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }
  }
}

ion-list {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  background-color: #fff !important;
}

ion-card {
  // --background: var(--ion-color-light) !important;
  // box-shadow: none !important;
  padding: 20px;
}

ion-card:focus {
  outline: none;
}

.datatable-body-cell-label,
ion-card-subtitle,
ion-label,
.datatable-header-cell-label {
  // font-family: 'DIN Pro';
  font-weight: 300;
  font-size: 16px !important;
  overflow: hidden;
  overflow-wrap: anywhere;
  // white-space: nowrap;
  text-overflow: ellipsis;
}

form ion-label,
ion-label.label {
  color: #737373 !important;
  padding-left: 18px;
}

form ion-item {
  margin-top: 10px;
}

ion-header ion-chip ion-label {
  overflow: inherit !important;
}

.label-stacked.sc-ion-label-md-h {
  transform: none !important;
  margin-bottom: 10px;
  color: #737373 !important;
}

ion-segment {
  background: transparent;

  justify-content: start !important;
}

.ion-segment label {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

ion-segment-button {
  text-transform: none;
  margin: 0;
}

.segment-button-checked {
  background: var(--ion-color-primary);
  color: white;

  ion-icon {
    color: white;
  }

  ion-label {
    color: white;
  }
}

.sc-ion-segment-md-h {
  background: var(--ion-color-tertiary, yellow) !important;
  display: flex;
  align-items: baseline;
}

ion-segment:hover {
  --background-hover: #e3e3e3;
}

.brandImages ion-col {
  text-align: center;
}

.dateTimeControl {
  border: none;
  // height: 45px;
  width: 100%;
  // margin: 0 0 3px 5px;
  padding-left: 18px;
  font-size: 14px;
}

.dateTimeControl:focus {
  border: none;
  outline: none;
}

.date-time-control ion-icon {
  font-size: 24px;
  cursor: pointer;
  position: absolute;
  right: 5px;
  padding: 10px;
  z-index: 3;
}
.checkbox{
background-color:transparent !important;
  ion-icon {
   font-size: 24px;
   cursor: pointer;
   position: absolute;
   padding: 10px;
   z-index: 3;
 }
}
.login-info > ion-row {
  padding: 10px;
}

//mat-select -- Amar
.mat-mdc-select {
  display: flex !important;
  font-family: var(--ion-font-family, Source Sans Pro);
  align-items: center;
  height: 36px;
  background-color: white;
  border-radius: 3px;
}

.mat-mdc-select-placeholder {
  font-size: 14px;
  font-weight: 300;
  color: #8e8e8c !important;
  opacity: 1 !important;
  // font-family: 'DIN Pro';
  line-height: 47px;
  padding-left: 0 !important;
}

.mat-mdc-select-search-input {
  width: 100%;
  box-shadow: inset 0 -3px 0 0 #dfdfdf;
  border: none !important;
  height: 47px !important;
  outline: none !important;
}

.mat-mdc-select-arrow {
  // width: 0 !important;
  // border: none !important;
  // border-left: 8px solid transparent !important;
  // border-right: 8px solid transparent !important;
  // border-top: 8px solid !important;
  margin: 4px 7px 0 0 !important;
  svg {
    width: 23px;
    height: 23px;
    color: var(--ion-color-primary);
  }
}

.mat-mdc-select-panel {
  background: #fff;
  //transform: none !important;
  //transform-origin: bottom !important;
  // font-size: 16px !important;
  min-width: 100% !important;
  border-radius: 0 !important;
  //max-height: 200px !important;
}

.mdc-menu-surface.mat-mdc-select-panel {
  padding: 0 !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.mat-mdc-select-value {
  line-height: 47px;
  padding-left: 8px;
  // font-size: 14px;
  padding-right: 20px;
}

.mat-mdc-option {
  min-height: 31px !important;
  font-size: 15px;
  padding-left: 10px !important;
}

.cdk-overlay-pane {
  margin-top: -5px;
  width: max-content !important;
  min-width: 200px;
}

ion-menu-toggle > ion-item {
  cursor: pointer;
}

ion-menu {
  --ion-background-color: #f0eeee;
  border: none;

  .header-md::after {
    width: 0;
    height: 0;
  }

  ion-toolbar {
    --background: #f0eeee;
  }

  ion-content {
    --background: #f0eeee;

    ion-item {
      --background: #f0eeee;

      &:hover {
        --background: #d7d2d2;
        background: #d7d2d2 !important;
      }
    }
  }

  ion-list {
    background: transparent;
  }
}

ion-content {
  //ion-select drop-down color set to primary.
  ion-select::part(icon) {
    color: var(--ion-color-primary);
    opacity: 1;
  }
}

ion-item.ion-touched .wysiwyg-editor.ng-invalid + .note-editor {
  border-bottom: 2px solid var(--ion-color-danger);
}

.ion-touched-me.ion-untouched {
  --highlight-background: red;
  --full-highlight-height: 2px;
}

//popper css
.tooltip {
  background-color: var(--ion-color-primary);
  border: 0;
  border-radius: 5px !important;
  padding: 9px 11px 9px 11px !important;
  color: white;
  font-weight: 10;
  font-size: 12px;
  box-shadow: none !important;
  border: none !important;
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-family: var(--ion-font-family);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  filter: alpha(opacity=0);
  opacity: 0;
  line-break: auto;
}

.tooltip.in {
  filter: alpha(opacity=90);
  opacity: 0.9;
}

.tooltip.top {
  padding: 5px 0 !important;
  margin-top: -3px;
}

.tooltip.right {
  padding: 0 5px;
  margin-left: 3px;
}

.tooltip.bottom {
  padding: 5px 0;
  margin-top: 3px;
}

.tooltip.left {
  padding: 0 5px;
  margin-left: -3px;
}

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: var(--ion-color-primary);
  border-radius: 4px;
}

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: var(--ion-color-primary);
}

.tooltip.top-left .tooltip-arrow {
  right: 5px;
  bottom: 0;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: var(--ion-color-primary);
}

.tooltip.top-right .tooltip-arrow {
  bottom: 0;
  left: 5px;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: var(--ion-color-primary);
}

.tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: var(--ion-color-primary);
}

.tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: var(--ion-color-primary);
}

.tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: var(--ion-color-primary);
}

.tooltip.bottom-left .tooltip-arrow {
  top: 0;
  right: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: var(--ion-color-primary);
}

.tooltip.bottom-right .tooltip-arrow {
  top: 0;
  left: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: var(--ion-color-primary);
}

.menubar {
  background-color: var(--ion-color-primary);
  width: 100%;
  z-index: 10;

  color: var(--ion-color-primary-contrast);

  padding: 4px 10px 4px 10px;
  // background: #f2f2f2;
  // background: #FFFFFF;

  .card-title {
    margin-bottom: 0 !important;
    padding-top: 10px;

    ion-col {
      padding-bottom: 0 !important;
    }
  }
}

.menubar ion-text {
  font-weight: 500;
  font-size: 18px;
  color: var(--ion-color-primary-text);
}

//loading

.overlay-hidden.sc-ion-loading-md-h {
  display: flex !important;
}

.custom-loading-spinner ~ .loading-wrapper {
  display: none !important;
}

//alert
//Ionic 6 patch ..not showing ion-alert
.overlay-hidden.sc-ion-alert-md-h {
  display: flex !important;
}

.overlay-hidden.sc-ion-alert-md-h {
  display: flex !important;
}

.custom-alert ~ .alert-wrapper,
.custom-alert ~ ion-backdrop:not(.custom-alert-backdrop) {
  display: none !important;
}

.alert-head.sc-ion-alert-md {
  text-align: center;
}

.alert-title {
  font-size: 16px !important;
  font-weight: 500;
}

.alert-checkbox-group.sc-ion-alert-md::-webkit-scrollbar,
.alert-message.sc-ion-alert-md::-webkit-scrollbar,
.alert-radio-group.sc-ion-alert-md::-webkit-scrollbar {
  width: 0.7em;
  display: block !important;
}

.alert-checkbox-group.sc-ion-alert-md::-webkit-scrollbar-thumb,
.alert-message.sc-ion-alert-md::-webkit-scrollbar-thumb,
.alert-radio-group.sc-ion-alert-md::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.alert-message.sc-ion-alert-md {
  text-align: center;
}

.alert-button-group.sc-ion-alert-md {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

//ion-select popup background
.alert-wrapper.sc-ion-alert-md {
  background-color: white;
}

ion-popover.localePopOver {
  --width: 200px !important;
}

.alert-button.sc-ion-alert-md {
  display: block;
  font-size: 14px;
  border-radius: 2px;
  margin-left: 0;
  margin-right: 8px;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
  background-color: transparent;
  color: var(--ion-color-primary, #3880ff);
  font-weight: 500;
  text-align: end;
  text-transform: uppercase;
  overflow: hidden;
  line-height: 20px;
}

.logo {
  margin: 0 auto;
  max-height: 150px;
}

.owldateTimeInput:focus-visible {
  outline: none;
}

.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
  color: var(--ion-color-primary-contrast);
  background-color: var(--ion-color-primary);
}

.owl-dt-calendar-table
  .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected) {
  border-color: var(--ion-color-primary);
}

.menubar {
  mat-select {
    font-size: 15px;
    // min-width: 200px;
  }
  ion-input {
    font-size: 15px !important;
    // min-width: 200px;
    --padding-start: 8px !important;
  }
}

.reservation-container {
  .reservation-info-container,
  .time-slot-container,
  .order-container,
  .confirmation-container {
    padding: 0 15px;
  }

  .order-container {
    ion-input {
      font-size: 16px !important;
      align-items: start;
    }
    .value {
      font-size: 16px;
      align-items: start;
      display: flex;
      flex-wrap: wrap;
      color: #282828;
      font-weight: 400;
    }
    ion-row {
      margin-bottom: 20px;
    }
    ion-label{
      line-height: 25px;
    }
  }

  .confirmation-container {
    ion-input {
      font-size: 16px !important;
      align-items: start;
    }
    .value {
      font-size: 16px;
      align-items: start;
      display: flex;
      flex-wrap: wrap;
      color: #282828;
      font-weight: 400;
    }
    ion-row {
      margin-bottom: 20px;
    }
    ion-label{
      line-height: 25px;
    }
  }

  .reservation-date-container {
    ion-label {
      font-weight: 500;
    }
  }
}

//SummerNote Fix Start
.note-modal-backdrop {
  display: none !important;
}
.ngx-summernote-view {
  color: black;
}
.ngx-summernote-view img {
  display: inline-block;
  max-width: 100%;
  width: auto;
}

.note-modal-footer {
  height: 50px !important;
}

.note-modal-content {
  margin: 100px auto !important;
}

.note-editor.note-frame .note-editing-area .note-codable {
  color: #ccc !important;
}
//SummerNote Fix END

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.login-container {
  ion-text {
    font-size: 16px;
  }
}

.page-title-outer-exception {
  padding: 5px 0px;
}

.mat-step-text-label {
  white-space: break-spaces;
  hyphens: auto;
}

ion-item.ion-touched .wysiwyg-editor.ng-invalid + .note-editor{
  border-bottom: 2px solid var(--ion-color-danger);
}

.wysiwyg-editor.ng-invalid.ng-dirty.ng-touched + .note-editor{
  border-bottom: 2px solid var(--ion-color-danger);
}

@media only screen and (max-width: 600px) {
}

@media only screen and (min-width: 600px) {
  .login-container {
    max-width: 600px;
  }

  .reservation-container {
    max-width: 870px;
  }
}


.ngx-modal-invoiceModel.nsm-dialog {
  // top: 40%;
  right: 2.10%;
  // transform: translateY(-50%);
  max-width: 850px !important;
  .nsm-content{
    padding: 0px !important;
    padding-bottom: 16px !important;
  } 
}

.ngx-modal-addInvoiceLineModel.nsm-dialog {
  // top: 40%;
  right: 2.10%;
  // transform: translateY(-50%);
  min-width: 900px !important;
  .nsm-content{
    padding: 0px !important;
  } 
}

.invoice-popup {
  .input-group,
  .item {
    background-color: #f5f5f5;
  }

  .input-group,
  ion-item {
    border-radius: 6px;
    margin-top: 0px !important;
  }

  ion-list {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .input-invoicebutton {
    position: relative;
    top: 4px;
    ion-icon {
      font-size: 26px;
    }
  }

  
  
}
.invoiceline-table {
  border: 1px solid var(--ion-color-light-shade);
  font-size: 14px;
  ion-col {
    padding: 0 5px;
  }
  .datatable-body {
    height: 570px !important;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
ion-input.currency-input{
  input{
    padding-left: 4px;
  }
}


// popper css
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  background-color: var(--ion-color-primary);
  border: none !important;
  border-radius: 5px !important;
  padding: 9px 11px !important;
  color: white;
  font-family: var(--ion-font-family);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center !important;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-wrap: break-word;
  white-space: normal;
  box-shadow: none !important;
  margin-right: 90px !important;
  max-width: 110px;
  min-width: 80px;
  opacity: 0;
  filter: alpha(opacity=0);
  top: -12px;
  left: -182px;
}

.datatable-header{
  background-color: var(--ion-color-table-footer-header) !important;
}